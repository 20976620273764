// document.addEventListener("DOMContentLoaded", () => {
//   const blocks = document.querySelectorAll(".recent-posts-list");
//   for (let i = 0; i < blocks.length; i += 1) {
//     const swiper = new Swiper(blocks[i], {
//       // Optional parameters
//       freeMode: false,
//       loop: true,
//       slidesPerView: 3,
//       spaceBetween: 38,
//       centeredSlides: true,

//       autoplay: {
//         delay: 5000,
//       },

//       // If we need pagination
//       // pagination: {
//       //   el: '.swiper-pagination',
//       //   clickable: true,
//       // },

//       // Navigation arrows
//       navigation: {
//         nextEl: ".swiper-button-next",
//         prevEl: ".swiper-button-prev",
//       },
//     });
//   }
// });

document.addEventListener("DOMContentLoaded", () => {
  const blocks = document.querySelectorAll(".recent-posts-list");
  for (let i = 0; i < blocks.length; i += 1) {
    const swiper = new Swiper(blocks[i], { // eslint-disable-line
      // Optional parameters
      autoplay: {
        delay: 2000,
      },
      lazy: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      freeMode: true,
      rewind: true,
      passiveListeners: true,
      watchOverflow: true,

      breakpoints: {
        280: {
          slidesPerView: "auto",
          spaceBetween: 16,
          loop: false,
        },
        768: {
          centeredSlides: false,
          slidesPerView: 2,
          spaceBetween: 30,
          loop: true,
        },
        1280: {
          centeredSlides: false,
          slidesPerView: 3,
          spaceBetween: 30,
          loop: true,
        },
      },
      // If we need pagination
      // pagination: {
      //   el: ".swiper-pagination",
      // },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }
});
